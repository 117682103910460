import React, { useState, useEffect, useRef } from "react";
import { ReactMediaRecorder } from "react-media-recorder";
import fixWebmDuration from "webm-duration-fix";
import { useLocation } from 'react-router-dom';
import {
  Typography,
  Button,
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import AIService from "./services/aiService";
import "./UIX/assets/css/SignInForm.css";
import header from "./UIX/assets/img/header.jpg";
import FeedbackPopup from "./compoments/Feedbackpopup"


const AudioRecorder = () => {
  const location = useLocation();
  const userName = location.state?.username; // Access the passed username
  const [transcription, setTranscription] = useState("");
  const [conversation, setConversation] = useState([]);

  const [transcriptionbox, setTranscriptionBox] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [summary, setSummary] = useState("");
  const [language, setLanguage] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [audioDuration, setAudioDuration] = useState(0);
  const [audioBlob, setAudioBlob] = useState(null);
  const [mediaBlob, setMediaBlob] = useState(null);
  const [loadingTranscription, setLoadingTranscription] = useState(false);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [openPermissionDialog, setOpenPermissionDialog] = useState(false);
  const [step, setStep] = useState(1);
  const audioRef = useRef(null);

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  useEffect(() => {
    if (mediaBlob) {
      handleRecordingStop(mediaBlob);
    }
  }, [mediaBlob]);

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
    setStep(2);
  };
  const handleGenerateConversation = () => {
    console.log(1,conversation)
    const conversationString = extractConversationFromTranscription(conversation);
    setConversation(conversationString);
  };
  const extractConversationFromTranscription = (conversation) => {
    let conversationString = "";

    // Iterate through the array
    for (let i = 0; i < conversation.length; i++) {
      const block = conversation[i].trim();

      // Use odd index for Speaker 1 (User) and even index for Speaker 2 (Assistant)
      if (i % 2 === 0) {
        conversationString += "User: " + block + "\n"; // Speaker 1 (User)
      } else {
        conversationString += "Assistant: " + block + "\n"; // Speaker 2 (Assistant)
      }
    }
    console.log(conversationString)
    return conversationString;
  };

  const uploadAudio = async (audioBlob) => {
    setTranscription("");
    setConversation([]);
    setLoadingTranscription(true);
    try {
      const data = await AIService.uploadAudio(audioBlob, language, "interview");
      console.log(data)
      if (data.transcription) {
        setTranscription(data.transcription[0]?.full_transcription || data.transcription["full_transcription"]);
        setConversation(data.transcription[0]?.sentences || data.transcription["sentences"]);
        setStep(3);
      } else {
        console.error("Error:", data.error);
      }
    } catch (error) {
      console.error("Error uploading audio:", error);
    } finally {
      setLoadingTranscription(false);
    }
  };

  const handleGenerateSummary = async () => {
    if (!transcription) return;

    setLoadingSummary(true);
    try {
      const today = new Date();

      // Format the date as MM-DD
      const formattedDate = `${String(today.getMonth() + 1).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;
      const formattedTime = `${String(today.getHours()).padStart(2, "0")}:${String(today.getMinutes()).padStart(2, "0")}:${String(today.getSeconds()).padStart(2, "0")}`;

      // Transcription template
      const transcriptionTemp = transcription + `Today the date is ${formattedDate} and the time is ${formattedTime}.`;
      const data = await AIService.generateSummary(transcriptionTemp, "interview", language);

      if (data.summary) {
        setSummary(data.summary);
        const today = new Date();
        const fileName = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;
        const audioBase64 = await blobToBase64(audioBlob);
        const medicalDocBody = {
          session_id: "",
          status: "completed",
          file_name: fileName,
          file_path: audioBase64,
          transcription_content: transcription || "",
          summarization_content: data.summary || "",
          summarization_method: "AI-based summarization",
          created_by: userName,
        };
        console.log(medicalDocBody);
        try {
          //const response = await MedicalService.addMedicalDocument(medicalDocBody);
          //console.log("Medical document created successfully:", response);
        } catch (error) {
          console.error("Error adding medical document:", error);
        }
      } else {
        console.error("Error:", data.error);
      }
    } catch (error) {
      console.error("Error generating summary:", error);
    } finally {
      setLoadingSummary(false);
    }
  };

  const handleRecordingStart = () => {
    setIsRecording(true);
    setAudioBlob(null);
    setAudioDuration(0);
    setTranscription("");
    setTranscriptionBox("");

    setSummary("");
  };

  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setAudioDuration((prevDuration) => prevDuration + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isRecording]);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleRecordingStop = async (mediaBlobUrl) => {
    setIsRecording(false);
    if (mediaBlobUrl) {
      try {
        // Fetch the blob directly from the mediaBlobUrl
        const blob = await fetch(mediaBlobUrl).then((r) => r.blob());
        setAudioBlob(blob);
        const blobUrl = URL.createObjectURL(blob);
        await sleep(1000); // Add 2-second delay
        const audio = new Audio(blobUrl);

        audio.onloadedmetadata = () => {
          const duration = Math.ceil(audio.duration);
          setAudioDuration(duration); // Set the duration for UI
          uploadAudio(blob); // Upload the blob
        };

        audioRef.current = audio;
      } catch (error) {
        console.error("Error in handleRecordingStop:", error);
      }
    }
  };

  const handleReset = () => {
    setTranscription("");
    setSummary("");
    setIsRecording(false);
    setAudioDuration(0);
    setAudioBlob(null);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
    }
    setStep(1);
  };

  const checkMicrophonePermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      setPermissionDenied(false);
    } catch (error) {
      setPermissionDenied(true);
      setOpenPermissionDialog(true);
    }
  };

  useEffect(() => {
    checkMicrophonePermission();
  }, []);


  return (
    <div className="container">
      <div className="my-body">
        <p className="sub-title">Lets talk to Paul.</p>

        <div className="step-container">
          <Step number={1} title="Select your language and start recording" active={step >= 1} />
          {step >= 1 && (
            <div className="content-box">
              <FormControl fullWidth>
                <Select
                  labelId="language-label"
                  value={language}
                  onChange={handleLanguageChange}
                >
                  <MenuItem value="en-US">English</MenuItem>
                  <MenuItem value="fr-FR">French</MenuItem>
                  <MenuItem value="es">Spanish</MenuItem>
                  <MenuItem value="ru">Russian</MenuItem>
                  <MenuItem value="zh">Chinese</MenuItem>
                  <MenuItem value="ar">Arabic</MenuItem>
                  <MenuItem value="pt">Portuguese</MenuItem>
                  <MenuItem value="de">German</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}

          <Step number={2} title="Begin your interview" active={step >= 2} />
          {step >= 2 && (
            <div className="content-box">
              <ReactMediaRecorder
                audio
                onStart={handleRecordingStart}
                onStop={handleRecordingStop}
                render={({ startRecording, stopRecording, status }) => (
                  <div className="recording-section">
                    <div className="recording-box">
                      {isRecording ? (
                        <>
                          <div className="live-recording-text">LIVE RECORDING</div>
                          <div className="sound-wave">
                            {[...Array(5)].map((_, index) => (
                              <div key={index} className="sound-bar"></div>
                            ))}
                          </div>
                          <div className="recording-time">
                            <span className="recording-dot"></span>
                            {new Date(audioDuration * 1000).toISOString().substring(audioDuration >= 3600 ? 11 : 14, 19)}
                          </div>
                          <div className="buttons-container">
                            <button
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                stopRecording();
                                setLoadingTranscription(true); // Trigger loading state while transcription is happening
                              }}
                              className="stop-button"
                            >
                              Stop
                            </button>
                          </div>
                        </>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={startRecording}
                          disabled={permissionDenied || loadingTranscription} // Disable when loading transcription
                          className="record-button"
                        >
                          Start Recording
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              />
            </div>
          )}

          {/* Loading and Transcription UI */}
          {loadingTranscription && (
            <div className="content-box">
              <div className="loading-box">
                <CircularProgress size={50} color="secondary" />
                <Typography variant="h6" gutterBottom>Transcription ...</Typography>
              </div> </div>

          )}

          {/* Transcription result with styled box */}
          {transcription && !loadingTranscription && (
            <div>
            <div className="content-box">
              <Typography variant="h6" gutterBottom>Transcription result</Typography>
              <Typography variant="body1">{transcription}</Typography>
              <Button
                variant="text"
                onClick={() => navigator.clipboard.writeText(transcription)}
                sx={{ mt: 1 }}
              >
                Copy
              </Button>
              </div>
             
            </div>

          )}

          
          <Step number={3} title="Generate your medical document" active={step >= 3} />
          {step === 3 && (
            <div className="content-box">
              {!summary && (
                <div className="button-container">
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleGenerateSummary}
                    disabled={!transcription || loadingSummary}
                    className="button"
                  >
                    {loadingSummary ?
                      <CircularProgress size={24} /> : "Generate"}
                  </Button>
                </div>
              )}
              {summary && (
                <>
                  <Typography variant="h6" gutterBottom>Summary</Typography>
                  <ReactMarkdown>{summary}</ReactMarkdown>
                  <Button
                    variant="text"
                    onClick={() => navigator.clipboard.writeText(summary)}
                    sx={{ mt: 1 }}
                  >
                    Copy
                  </Button>
                </>
              )}
            </div>
          )}
        </div>

        <button variant="contained" color="secondary" className="reset-button" onClick={handleReset}>
          Reset
        </button>


        <Dialog open={openPermissionDialog} onClose={() => setOpenPermissionDialog(false)}>
          <DialogTitle>Microphone Access Denied</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              It seems like the microphone access was denied. Without this permission, recording won't work.
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Please garanted on you own browser sitings
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenPermissionDialog(false)} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

const Step = ({ number, title, active }) => (
  <div className="step">
    <div className={`step-number-${active ? 'active' : 'inactive'}`}>{number}</div>
    <Typography
      className="step-title"
      sx={{
        fontWeight: active ? 'bold' : 'normal',
        color: active ? 'black' : 'grey',
      }}
    >
      {title}
    </Typography>
  </div>
);
export default AudioRecorder;