import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import AuthService from "../../services/authService";
import header from "../../UIX/assets/img/header.jpg";
import "../../UIX/assets/css/SignInForm.css";


function SignInForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = { "username": email, "password": password };
    try {
      const response = await AuthService.login(data);
     // const currentUser = AuthService.getCurrentUser();

      if (response.data.status === "success") {
        swal({
          icon: "success",
          title: "Success",
          text: "Login successful",
          buttons: false,
          timer: 2000,
        }).then(() => {
          navigate("/audioRecorder", {
            replace: true,
            state: { username: email } // Pass the username/email as state
          });
        });
      } else {
        swal({
          icon: "error",
          title: "Error",
          text: "Login failed. Please check your credentials.",
          buttons: false,
          timer: 2000,
        });
      }
    } catch (error) {
      console.error("Login failed:", error);
      swal({
        icon: "error",
        title: "Error",
        text: "An error occurred. Please try again later.",
        buttons: false,
        timer: 2000,
      });
    }
  };

  return (
    <div className="container">
      <h1 className="main-title">- Interview Assistance -</h1>
      <p className="sub-title">
        Transcribes and generates notes.
      </p>

      <form onSubmit={handleSubmit} className="form">
        <div className="input-group">
          <label htmlFor="email" className="label">
            Email address
          </label>
          <input
            type="text"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input-field"
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="password" className="label">
            Password
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input-field"
            required
          />
        </div>
        <p className="footer-text">
          Thank you for being pioneers in transforming medical documentation. Your
          insights drive our innovation.
        </p>
        <button type="submit" className="button">
          SIGN IN
        </button>
      </form>
    </div>
  );
}

export default SignInForm;