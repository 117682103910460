import instance from "./http-common";
import TokenService from "./tokenService";

class AuthService {
  async signUp(data) {
    try {
      const response = await instance.post("/signup", data);
      return response.data;
    } catch (error) {
      console.error("Sign up failed:", error);
      throw error;
    }
  }

  async login(data) {
    try {
      console.log("Service called with data:", data);
      const response = await instance.post("/users/login", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(response)
      console.log("User data:", response.data.user);
      TokenService.setUser(response.data.user);
      return response;
    } catch (error) {
      console.error("Login failed:", error);
      throw error;
    }
  }

  async forgotPassword(data) {
    try {
      const response = await instance.post("/forgotPassword", data);
      return response.data;
    } catch (error) {
      console.error("Forgot password failed:", error);
      throw error;
    }
  }

  logout() {
    TokenService.removeUser();
  }

  getCurrentUser() {
    return TokenService.getUser();
  }
}

export default new AuthService();
