import instance from "./http-common"; // Ensure you have a configured Axios instance
const endpointStart ="https://recordai-1057981729709.us-central1.run.app/"
//const endpointStart ="http://127.0.0.1:5000/"

class AIService {
  // Upload audio and get transcription
  async uploadAudio(audioBlob, language, category) {
    //const endpoint ="https://recordai-1057981729709.us-central1.run.app/transcribe_long"

    //const endpoint ="https://recordai-1057981729709.us-central1.run.app/transcribe_long"

    const formData = new FormData();
    formData.append("file", audioBlob, "recording.wav");
    formData.append("language_code", language);
    formData.append("category", category);
    const endpoint = endpointStart +  `transcribe_long `
    const response = await instance.post(endpoint, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    console.log(response.data)
    return response.data;
  }



  

  async sendFeedback(feedbackData) {
   // const endpoint = `https://recordai-1057981729709.us-central1.run.app/send-feedback-outlook`; // Your Flask endpoint
   const endpoint = endpointStart +  `send-feedback-outlook `

    try {
        const response = await fetch(endpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(feedbackData),
        });

        if (!response.ok) {
            const message = `Error sending feedback: ${response.status}`;
            console.error(message);
            throw new Error(message);
        }
        console.log(response)
        return response; // Return the response data if successful
    } catch (error) {
        console.error("Error sending feedback:", error);
        throw error; // Re-throw the error to be handled by the caller
    }
}

  // Generate summary
  async generateSummary(transcription, category, language) {
    //const endpoint = `https://recordai-1057981729709.us-central1.run.app/generate-summary-claude?language=${language}`;
    const endpoint = endpointStart +  `generate-summary-claude?language=${language}`

    const response = await instance.post(
      endpoint,
      { transcription, category },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  }
}

export default new AIService();
