import React from 'react';
import ReactDOM from 'react-dom/client';

import Login from './auth/signIn';  // Your Login component
import AudioRecorder from './AudioRecorder';  // Your Login component


import reportWebVitals from './reportWebVitals';
import { Route, BrowserRouter as Router,Routes ,Navigate } from 'react-router-dom';  // Import HashRouter

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>  {/* Updated from <Switch> to <Routes> in React Router v6 */}
        <Route path="/" element={<Navigate to="/sign-in" />} />  {/* Use <Navigate> for redirect */}
        <Route path="/sign-in" element={<Login />} />  {/* Use <element> prop */}
        <Route path="/audioRecorder" element={<AudioRecorder />} />  {/* Use <element> prop */}
      </Routes>
    </Router>
  </React.StrictMode>
);

// Measure app performance
reportWebVitals();
